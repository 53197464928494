<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 680 529.32">
    <path
      fill="currentColor"
      stroke="0"
      d="M393.58,386.2h-40.81c-4.69,0-8.53,3.84-8.53,8.53v88.17l37.23,19.56h12.11c4.69,0,8.53-3.84,8.53-8.53v-99.2c0-4.69-3.84-8.53-8.53-8.53Z"
    />
    <rect
      fill="currentColor"
      stroke="0"
      x="421.04"
      y="386.2"
      width="57.88"
      height="116.26"
      rx="8.53"
      ry="8.53"
    />
    <rect
      fill="currentColor"
      stroke="0"
      x="497.84"
      y="386.2"
      width="57.88"
      height="116.26"
      rx="8.53"
      ry="8.53"
    />
    <path
      fill="currentColor"
      stroke="0"
      d="M275.98,386.2c-4.69,0-8.53,3.84-8.53,8.53v45.41c2.17,2.41,4.81,4.45,7.83,5.96l50.04,26.3v-77.66c0-4.69-3.84-8.53-8.53-8.53h-40.81Z"
    />
    <path
      fill="currentColor"
      stroke="0"
      d="M439.63,293.91c1.07-8.92,5.7-13.31,11.77-17.84,2.34-1.74,4.62-3.57,6.85-5.44,7.2-6.01,7.89-13.87,1.54-20.84-4.31-4.74-8.89-9.26-13.6-13.61-6.81-6.28-14.2-5.89-20.44,1.08-3.75,4.19-7.24,8.65-10.57,13.19-2.23,3.03-4.75,4.42-8.66,4.78-19.1,1.79-31.6,17.76-28.77,36.52.59,3.88-.73,6.64-3.03,9.53-18.2,22.77-36.3,45.62-54.43,68.44-1.33,1.67-2.66,3.35-3.88,5.1-.94,1.34-1.41,2.83-.12,4.26,1.48,1.64,3.12,1.26,4.71.19,1.12-.76,2.18-1.62,3.25-2.47,21.65-17.13,43.3-34.26,64.96-51.38,5.7-4.51,9.54-8.59,18.07-6.84,16.64.09,30.57-9.78,32.35-24.67ZM409.07,299.88c-7.16-.03-12.9-5.75-12.95-12.9-.06-7.5,5.15-12.79,12.7-12.9,7.44-.11,13.17,5.16,13.45,12.35.28,7.13-5.96,13.48-13.2,13.45Z"
    />
    <path
      fill="currentColor"
      stroke="0"
      d="M408.92,116.27c4.89.03,8-2.68,8.87-7.5.51-2.83.53-5.76.79-8.89-.28-2.87-.35-5.98-.92-9-.86-4.57-3.99-7.28-8.48-7.33-4.78-.06-7.99,2.76-8.85,7.59-1.06,5.95-1.06,11.96.1,17.89.89,4.55,3.84,7.21,8.48,7.24Z"
    />
    <path
      fill="currentColor"
      stroke="0"
      d="M563.97,271.68c.1,4.59,2.64,7.68,7.21,8.53,3.01.56,6.13.59,8.91.83,3.01-.23,5.73-.26,8.39-.68,5.04-.8,8.22-3.89,8.17-8.98-.05-4.79-3.05-7.87-7.86-8.65-5.95-.97-11.95-1.08-17.85.23-4.51,1-7.06,4.08-6.96,8.72Z"
    />
    <path
      fill="currentColor"
      stroke="0"
      d="M502.01,108.32c-3.76-1.56-7.46-1.08-10.02,2.14-3.86,4.85-7.04,10.23-9.32,15.99-1.57,3.97-.31,7.67,3.24,10.27,3.15,2.31,6.84,2.66,9.69.15,6.1-5.37,8.89-12.87,11.77-19.23-.21-4.86-1.76-7.83-5.36-9.32Z"
    />
    <path
      fill="currentColor"
      stroke="0"
      d="M560.74,173.81c-5.28,2.31-10.41,5.21-15.11,8.54-5.63,3.99-4.85,11.77,1.06,14.92,6.85,3.65,25.8-7.39,25.95-15.11.13-6.45-5.91-10.97-11.91-8.34Z"
    />
    <path
      fill="currentColor"
      stroke="0"
      d="M570.75,366.62c2.49-3.21,3.1-7.19.45-10.22-5.33-6.08-12.67-9.13-18.91-11.67-5.44.07-8.22,2.12-9.74,5.9-1.59,3.97-.47,7.58,2.8,9.8,4.93,3.35,10.19,6.3,15.57,8.87,3.66,1.75,7.31.56,9.84-2.69Z"
    />
    <path
      fill="currentColor"
      stroke="0"
      d="M327.22,138.43c5.41-.03,10.02-5.43,8.42-10.85-1.94-6.55-5.46-12.55-10.07-17.57-4.02-4.38-10.96-2.88-13.77,2.41-3.57,6.71,7.9,26.06,15.42,26.02Z"
    />
    <path
      fill="currentColor"
      stroke="0"
      d="M409.26,0c-67.34-.02-128.98,24.55-176.42,65.2,24.43,5.43,43.58,15.95,57.18,31.48.46.52.89,1.05,1.32,1.57,21.7-14.8,46.39-25.71,73.13-31.6,14.67-3.24,29.53-4.61,44.54-4.56,79.41-1.43,157.32,48.51,190,121.05,12.44,27.61,19.48,56.44,19.29,86.51-.35,55.78-18.46,103.47-54.25,142.52-.62,1.32-1.33,2.62-2.13,3.9h76.53c26.31-41.86,41.54-91.45,41.54-144.65C679.99,121.5,558.84.06,409.26,0Z"
    />
    <path
      fill="currentColor"
      stroke="0"
      d="M100.72,453.88L0,512.45v16.86l380.2-.69v-16.13l-108.55-57.05c-12.47-6.24-20.38-18.98-20.38-32.97v-37.86c2.74-3.06,5.84-7.07,9.14-11.74,13.2-18.6,23.1-39.04,30-60.48,12.41-3.83,21.55-15.25,21.55-28.83v-40.42c0-8.91-3.95-16.88-10.13-22.43v-58.47s12.03-90.92-111.19-90.92-111.13,90.92-111.13,90.92v58.47c-6.17,5.56-10.13,13.53-10.13,22.43v40.42c0,10.63,5.63,19.98,13.98,25.44,10.13,43.98,36.57,75.61,36.57,75.61v36.97c0,13.41-7.4,25.83-19.2,32.28h0ZM100.72,453.88"
    />
  </svg>
</template>
